@use '@angular/material' as mat;
@use "imagingyou-theme";

@include mat.core();

//$my-primary: mat.define-palette(mat.$indigo-palette, 500);
//$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
/*
$my-theme: mat.define-light-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));
*/

//@include mat.all-component-themes($my-theme);

// Farbwerte
$frontend-primary: mat.define-palette(imagingyou-theme.$md-imgyprimary, 500, 200, 800);
$frontend-accent: mat.define-palette(imagingyou-theme.$md-imgysecondary,500, 200, 800);

// Farbschema erstellen
$frontend-theme: mat.define-light-theme((
  color: (
    primary: $frontend-primary,
    accent: $frontend-accent,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

// Farbschema benutzen
@include mat.all-component-themes($frontend-theme);




/* FONTS */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/Montserrat-Bold.ttf") format("opentype");
}

//html, body { height: 100%; }
body { margin: 0; font-family: Montserrat, "Helvetica Neue", Roboto, sans-serif; }


.toolbarNavigation {
  background-color: imagingyou-theme.$colorPrimaryDark;
}

#main {
  max-width: imagingyou-theme.$maxWidth-content;
  margin: 50px auto 150px;
}

.cdk-overlay-pane {
  width: 640px;
}

.app-language-selector {
  display: flex;
  //display:none;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 15px;
}

app-login, app-register {
  height: calc(100vh - 154px);
  position: relative;
  display: block;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: -moz-inline-block;
  display: inline-block;
}

.mat-mdc-cell.link {
  cursor: pointer;
}

@media all and (max-width: imagingyou-theme.$maxWidth-content) {
  #main {
    margin: 50px;
  }
}

@media all and (max-width: 768px) {
  #main {
    margin: 25px;
  }
}

