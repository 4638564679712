//TYPOGRAPHY
$fontSizeLarge: 2rem;
$fontSizeRegular: 1rem;
$fontSizeSmall: 0.8rem;

//WIDTHS
$maxWidth-content: 960px;

// COLORS
$colorPrimary: #209dd9;
$colorPrimaryLight: #90ceec;
$colorPrimaryDark: #1381ca;

$colorAccent: #e40c7d;
$colorAccentLight: #f286be;
$colorAccentDark: #d90660;

$md-imgyprimary: (
  50 : #e4f3fa,
  100 : #bce2f4,
  200 : #90ceec,
  300 : #63bae4,
  400 : #41acdf,
  500 : #209dd9,
  600 : #1c95d5,
  700 : #188bcf,
  800 : #1381ca,
  900 : #0b6fc0,
  A100 : #ecf6ff,
  A200 : #b9ddff,
  A400 : #86c4ff,
  A700 : #6db8ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$md-imgysecondary: (
  50 : #fce2ef,
  100 : #f7b6d8,
  200 : #f286be,
  300 : #ec55a4,
  400 : #e83091,
  500 : #e40c7d,
  600 : #e10a75,
  700 : #dd086a,
  800 : #d90660,
  900 : #d1034d,
  A100 : #fff9fb,
  A200 : #ffc6d7,
  A400 : #ff93b3,
  A700 : #ff7aa2,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

#main {

  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;

  h2 {
    margin: 2rem 0 1rem;
  }

  a, a:active, a:visited {
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {
    text-decoration: underline;
  }
  .clickable {
    cursor: pointer;
  }
}

.icon-flipped {
  -o-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
}


// Define a custom typography config that overrides the font-family
/*
$custom-typography: mat-typography-config(
  $font-family: 'Montserrat, "Helvetica Neue", Roboto, sans-serif'
);
 */

// Override typography for all Angular Material, including mat-base-typography and all components.
/*
@include angular-material-typography($custom-typography);
 */

a:not([href]) {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

//PRINT STYLES
.print-me {
  display: none;
}

@media print {
  $fontSizeLarge: 16pt;
  $fontSizeRegular: 11pt;
  $fontSizeSmall: 9pt;
  app-order-deliverynote {
    border: 1px solid transparent!important;

  }
  #main {
    padding: 0;
    .mat-toolbar {
      box-sizing: border-box;
    }
  }
  body { font-size: 10pt; }
  html, body { height: 99%; }

  mat-toolbar-row {
    display: none;
  }

  .no-print, .no-print * {
    display: none !important;
    border: 0px;
    margin: 0;
    padding: 0;
  }
  .print-me {
    display: block!important;
  }
}

@page
{
  // auto is the initial value
  size:  auto;
  // this affects the margin in the printer settings
  margin: 0mm;
}


//Material-Anpassungen
:root {
  --mat-table-header-headline-font: Montserrat;
  --mat-table-header-headline-color: #666666;
  --mat-table-header-headline-line-height: 1rem;
  --mat-table-header-headline-size: 0.75rem;
  --mat-table-header-headline-weight: 400;

  --mdc-filled-text-field-container-color: #fcfcfc;
}
